import { del, get, post, put } from './request';

export const login = (code: string) => post('qy/login', { code });
export const wxLogin = (code: string) => post('wx/login', { code });
export const getSwiper = () => get('qy/slideshow/list');

// 调用企业微信jssdk需要生成的签名
export const generateWxConfigSign = (url: string) => post('qy/config/sign', { url });

export const getCarNumberList = () => get('qy/useful/car_number');
export const addCarNumber = (data: CarNumberParams) => post('qy/useful/car_number', data);
export const sortCarNumber = (idList: number[]) => put('qy/useful/car_number/sort', { idList });
export const delCarNumber = (id: number) => del('qy/useful/car_number', { id });
// 常用车牌前5条数据查询
export const getFiveCarNumbers = () => get('qy/useful/car_number/five');

export interface CarNumberParams {
  carNumber: string;
  remark: string;
}
