import React, { useEffect, Suspense, useState } from 'react';
import { useRoutes } from 'react-router-dom';
// import eruda from 'eruda';
import routes from './routes';
import { LoginCheck } from '@/utils/general';
import '@/assets/fonts/iconfont';
import './App.less';
// import VConsole from 'vconsole';

// const vConsole = new VConsole();
function App() {
  const element = useRoutes(routes);
  const [loading, setLoading] = useState<boolean>(process.env.REACT_APP_ENV === 'development' ? false : true);

  useEffect(() => {
    LoginCheck(() => setLoading(false));
    // eruda.init();
  });

  return (
    <div className="App">
      <div className="content">{loading ? <div>登录中</div> : <Suspense>{element}</Suspense>}</div>
    </div>
  );
}

export default App;
