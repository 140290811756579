import qs from 'qs';
import { login, wxLogin } from '@/api/common';

export const PAGE_SIZE = 6; // 分页条数

export const LoginCheck = (cb?: () => void) => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  // 微信登录
  if (usingWxLogin()) {
    if (params.code) {
      const token = sessionStorage.getItem('wxtoken');
      sessionStorage.setItem('wxcode', params.code as string);
      if (!token) {
        wxLogin(params.code as string).then((res) => {
          if (res.code === 0) {
            sessionStorage.setItem('wxtoken', res.data.token);
            cb?.();
          }
        });
      } else {
        cb?.();
      }
    }
    return;
  }

  // 企业微信登录
  if (params.code || sessionStorage.getItem('qycode')) {
    const code = params.code || sessionStorage.getItem('qycode');
    const token = sessionStorage.getItem('qytoken');
    params.code && sessionStorage.setItem('qycode', params.code as string);
    if (!token) {
      // 登录
      login(code as string).then((res) => {
        if (res.code === 0) {
          console.log(res.data);
          sessionStorage.setItem('qytoken', res.data.token);
          sessionStorage.setItem('qyuser', JSON.stringify(res.data));
          cb?.();
        }
      });
    } else {
      cb?.();
    }
  }
};

// 是否走微信登录
export const usingWxLogin = () => {
  const path = window.location.pathname.toLocaleLowerCase();
  return path.startsWith('/wx');
};

// 加载生成二维码的文件
const loadScript = () => {
  return new Promise((resolve) => {
    const scriptEl = document.createElement('script');
    scriptEl.type = 'text/javascript';
    scriptEl.src = '/libs/qrcode.min.js';
    scriptEl.onload = (res) => {
      resolve(res);
    };
    document.body.appendChild(scriptEl);
  });
};
// 生成二维码
export const generateCode = (str: string, dom: HTMLSpanElement | HTMLDivElement | Element, instance?: any) => {
  return new Promise((resolve, reject) => {
    if (!str || !dom) {
      reject(new Error('no str or dom'));
    }
    if (!window.QRCode) {
      loadScript().then(() => {
        resolve(generateCode(str, dom, instance));
      });
    } else {
      let res = instance;
      res = new window.QRCode(dom, {
        text: str,
        width: 239,
        height: 239,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: window.QRCode.CorrectLevel.H,
      });
      resolve(res);
    }
  });
};

export function IDNumberCheck(value: string): boolean {
  const multiplier = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 前17位相乘的系数
  const lastNum = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]; // 余数拼配的最后一位
  if (
    value.match(
      /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
    )
  ) {
    let total = 0;
    if (value.length === 18) {
      for (let i = 0; i < 17; i++) {
        total += Number(value[i]) * multiplier[i];
      }
      const mod = total % 11;
      const last = lastNum[mod];
      if (
        (typeof last === 'number' && last === Number(value[17])) ||
        (typeof last === 'string' && value[17].toUpperCase() === last)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  return false;
}
