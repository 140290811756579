import React, { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import qs from 'qs';
import loadable from '@/components/Loadable';
import { usingWxLogin } from '@/utils/general';

const IndexPage = lazy(() => import('@/views/Index/Index'));
const PlateManagementPage = lazy(() => import('@/views/PlateManagement/PlateManagement'));
const VisitorPage = lazy(() => import('@/views/Visitor/List/List'));
const VisitorDetailPage = lazy(() => import('@/views/Visitor/Detail/Detail'));
const VisitorFormPage = lazy(() => import('@/views/Visitor/Form/Form'));
const VisitorHistoryPage = lazy(() => import('@/views/Visitor/History/History'));
const VisitorGuidePage = lazy(() => import('@/views/Visitor/Guide/Guide'));
const ParkingPage = lazy(() => import('@/views/Parking/List/List'));
const ParkingHistoryPage = lazy(() => import('@/views/Parking/History/History'));
const ParkingPersonalFormPage = lazy(() => import('@/views/Parking/PersonalForm/PersonalForm'));
const ParkingDepartmentFormPage = lazy(() => import('@/views/Parking/DepartmentForm/DepartmentForm'));
const ParkingDetailPage = lazy(() => import('@/views/Parking/Detail/Detail'));
const ParkingGuidePage = lazy(() => import('@/views/Parking/Guide/Guide'));
const SelfReservation = lazy(() => import('@/views/WeiXin/SelpReservation/SelfReservation'));
const TimelinessCode = lazy(() => import('@/views/WeiXin/TimelinessCode/TimelinessCode'));
const SingleCode = lazy(() => import('@/views/WeiXin/SingleCode/SingleCode'));

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { search, pathname } = useLocation();
  const params = qs.parse(search, { ignoreQueryPrefix: true });

  const isWxLogin = usingWxLogin();
  const isLogin = isWxLogin ? sessionStorage.getItem('wxtoken') : sessionStorage.getItem('qytoken');
  if (process.env.REACT_APP_ENV !== 'development' && !isLogin && !params.code && pathname !== '/nocode') {
    return <Navigate to="/nocode" />;
  }
  return children;
};

const routes: RouteObject[] = [
  {
    path: '/index',
    element: (
      <RequireAuth>
        <IndexPage />
      </RequireAuth>
    ),
  },
  {
    path: '/plate',
    element: (
      <RequireAuth>
        <PlateManagementPage />
      </RequireAuth>
    ),
  },
  {
    path: '/visitor',
    element: (
      <RequireAuth>
        <VisitorPage />
      </RequireAuth>
    ),
  },
  {
    path: '/visitor/:id',
    element: (
      <RequireAuth>
        <VisitorDetailPage />
      </RequireAuth>
    ),
  },
  {
    path: '/visitor/form',
    element: (
      <RequireAuth>
        <VisitorFormPage />
      </RequireAuth>
    ),
  },
  {
    path: '/visitor/history',
    element: (
      <RequireAuth>
        <VisitorHistoryPage />
      </RequireAuth>
    ),
  },
  {
    path: '/visitor/guide',
    element: (
      <RequireAuth>
        <VisitorGuidePage />
      </RequireAuth>
    ),
  },
  {
    path: '/parking',
    element: (
      <RequireAuth>
        <ParkingPage />
      </RequireAuth>
    ),
  },
  {
    path: '/parking/history',
    element: (
      <RequireAuth>
        <ParkingHistoryPage />
      </RequireAuth>
    ),
  },
  {
    path: '/parking/:id',
    element: (
      <RequireAuth>
        <ParkingDetailPage />
      </RequireAuth>
    ),
  },
  {
    path: '/parking/form/personal',
    element: (
      <RequireAuth>
        <ParkingPersonalFormPage />
      </RequireAuth>
    ),
  },
  {
    path: '/parking/form/department',
    element: (
      <RequireAuth>
        <ParkingDepartmentFormPage />
      </RequireAuth>
    ),
  },
  {
    path: '/parking/guide',
    element: (
      <RequireAuth>
        <ParkingGuidePage />
      </RequireAuth>
    ),
  },
  // 需要使用微信登录的路由统一以/WX开头
  {
    path: '/WXself',
    element: (
      <RequireAuth>
        <SelfReservation />
      </RequireAuth>
    ),
  },
  {
    /**
     * 与生成二维码有关，
     * 以下文件同步修改
     * 1. src/typings/enum.ts  修改QRCodeTypeList 中的path
     * 2. pc管理系统的项目中src/typings/enum.ts  修改QRCodeTypeList 中的path
     */
    path: '/WXtime',
    element: (
      <RequireAuth>
        <TimelinessCode />
      </RequireAuth>
    ),
  },
  {
    path: '/WXsingle', // 同上
    element: (
      <RequireAuth>
        <SingleCode />
      </RequireAuth>
    ),
  },
  {
    path: '/nocode',
    element: <div>no code</div>,
  },
  {
    path: '/',
    // element: <Navigate to="/index" />,
    element: (
      <RequireAuth>
        <IndexPage />
      </RequireAuth>
    ),
  },
  {
    path: '*',
    element: <div>no match</div>,
  },
];

export default routes;
